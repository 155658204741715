<template>
  <div class="col-12 col-lg-6 c">
    <div class="card card-body">
      <div id="progress-wrp">
        <div class="progress-bar"></div>
        <div class="status">0%</div>
      </div>
      <div class="form-group">
        <label for="">اختر ملف الارشاد الطلابي من نور</label>
        <input type="file" class="form-control" id="ingredient_file" />
      </div>
    </div>
    <br /><br />
    <div class="card card-body text-center">
      لحذف الطلاب فيما بعد، <br />من خلال حسابكم في التحضير الذكي<br />نضغط على
      حذف بيانات النظام -> ونحدد بيانات الطالب ->ونضغط حذف<br />
      <br />
      <img
        :src="require('@/assets/images/how-delete.png')"
        class="c"
        style="width: 80%"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  created() {
    var g = this;
    var Upload = function (file) {
      this.file = file;
    };

    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("files[]", this.file, this.getName());
      formData.append("jwt", user.jwt);
      formData.append("type", "1");

      $.ajax({
        type: "POST",
        url: api2 + "/user/students/import",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          // your callback here
          alert(data.response);
          location.reload();
        },
        error: function (error) {
          // handle error
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    };

    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()
        $("#progress-wrp").fadeIn();
        // execute upload
        upload.doUpload();
      });
    }, 500);
  },
};
</script>

<style>
#progress-wrp {
  display: none;
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: seagreen;
  color: white;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #ffff;
}
</style>